import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import withLocation from "./withLocation"
import SEOHeader from "../components/seo-header"
import { Helmet } from "react-helmet"
import Axios from "axios"
// import SuggestionCard from "./Card/suggestioncard"
import SuggestionCardVertical from "./Card/suggestion_card_vertical"
const SuggestionComponent = ({ search }) => {
  var { searchId } = search
  const [Lists, SetLists] = useState("GoFloaters")
  const [spaceType, setspaceType] = useState("officeSpace")
  const [spaces, SetSpaces] = useState([])
  const [cities, setCities] = useState([])
  const [searchDescription, setsearchDescription] = useState("")
  const [citySelected, setcitySelected] = useState([])
  const [spaceSize, setspaceSize] = useState([])
  const [spaceFilter, setspaceFilter] = useState("All")
  const [Loader, SetLoader] = useState("load")
  const [filterStyle, setfilterStyle] = useState("")
  const [mobileFilterSecondary, setmobileFilterSecondary] = useState(false)
  const [searchBottomText, setsearchBottomText] = useState("")
  const [listingFilter, setlistingFilter] = useState([])
  const [inCity, setinCity] = useState([])

  const fetchDetails = async () => {
    const response = await Axios.get(
      `https://gofloaters.web.app/spaces/spaceSuggestions?searchId=${searchId}`,
      {}
    )
      .then(response => {
        if (response.status === 200) {
          SetLists(response.data.searchTitle)
          let type = response.data.spaceType
          if (type === "monthlyofficeSpace") {
            setspaceType("office-space")
          } else if (type === "dailyofficeSpace") {
            setspaceType("coworking-space")
          } else {
            setspaceType("meeting-space")
          }
          let responsevalue = Object.values(response.data.searchResults)
          setCities(response.data.cities)
          SetSpaces(responsevalue)
          setsearchDescription(response.data.searchDescription)
          setcitySelected(response.data.cities[0])
          setsearchBottomText(response.data.searchBottomText)
          SetLoader("completed")
        }
      })
      .catch(e => {})
  }
  useEffect(() => {
    SetLoader("loading")
    fetchDetails()
  }, [])
  const spaceIds = spaces

  const changeFilter = (filterText, spaceType) => {
    setmobileFilterSecondary(false)
    setfilterStyle(filterText)
    if (filterText === "Featured") {
      spaceIds.sort(function (a, b) {
        if (spaceIds.isFeatured > spaceIds.isFeatured) {
          return -1
        }
      })
    }
    if (filterText === "PriceLow") {
      if (spaceType === "meeting-space") {
        spaceIds.sort(function (a, b) {
          if (Number(a.priceperhr) < Number(b.priceperhr)) {
            return -1
          }
        })
        spaceIds.sort(function (a, b) {
          if (Number(a.priceperday) < Number(b.priceperday)) {
            return -1
          }
        })
      } else if (spaceType === "coworking-space") {
        spaceIds.sort(function (a, b) {
          if (Number(a.priceperday) < Number(b.priceperday)) {
            return -1
          }
        })
      } else {
        spaceIds.sort((a, b) => {
          if (Number(a.pricepermonth) < Number(b.pricepermonth)) {
            return -1
          }
        })
      }
    }

    if (filterText === "PriceHigh") {
      if (spaceType === "meeting-space") {
        spaceIds.sort(function (a, b) {
          if (Number(a.priceperhr) > Number(b.priceperhr)) {
            return -1
          }
        })
        spaceIds.sort(function (a, b) {
          if (Number(a.priceperday) > Number(b.priceperday)) {
            return -1
          }
        })
      } else if (spaceType === "coworking-space") {
        spaceIds.sort(function (a, b) {
          if (Number(a.priceperday) > Number(b.priceperday)) {
            return -1
          }
        })
      } else {
        spaceIds.sort((a, b) => {
          if (Number(a.pricepermonth) > Number(b.pricepermonth)) {
            return -1
          }
        })
      }
    }
    if (filterText === "User Rating") {
      spaceIds.sort(function (a, b) {
        if (Number(a.rating) > Number(b.rating)) {
          return -1
        }
      })
    }
    if (filterText === "Nearest") {
      spaceIds.sort(function (a, b) {
        if (Number(a.pincode) > Number(b.pincode)) {
          return -1
        }
      })
    }
    if (filterText === "Popular") {
      spaceIds.sort(function (a, b) {
        if (Number(a.bookingcount) > Number(b.bookingcount)) {
          return -1
        }
      })
    }
  }

  const Sorting = props => {
    return (
      <div>
        <div className="DesktopOnly">
          <div className="row alignersorting sortingCardList">
            <div className="col-md-2">
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "18px",
                  color: "#2193b0",
                }}
              >
                SORT BY:
              </p>
            </div>
            <div className="col-md-2">
              <div
                className={
                  filterStyle === "Popular"
                    ? "sortingCard active"
                    : "sortingCard"
                }
                onClick={() => {
                  changeFilter("Popular", spaceType)
                }}
              >
                <p>Popular</p>
                <span>Frequently booked</span>
              </div>
            </div>

            <div className="col-md-2">
              <div
                className={
                  filterStyle === "User Rating"
                    ? "sortingCard active"
                    : "sortingCard"
                }
                onClick={() => {
                  changeFilter("User Rating", spaceType)
                }}
              >
                <p>User Rating</p>
                <span>Highest first</span>
              </div>
            </div>
            <div className="col-md-2">
              <div
                className={
                  filterStyle === "PriceLow"
                    ? "sortingCard active"
                    : "sortingCard"
                }
                onClick={() => {
                  changeFilter("PriceLow", spaceType)
                }}
              >
                <p>Price</p>
                <span>Lowest first</span>
              </div>
            </div>
            <div className="col-md-2">
              <div
                className={
                  filterStyle === "PriceHigh"
                    ? "sortingCard active"
                    : "sortingCard"
                }
                onClick={() => {
                  changeFilter("PriceHigh", spaceType)
                }}
              >
                <p>Price</p>
                <span>Highest first</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row alignersorting">
          <div className="MobileOnly ">
            <span
              onClick={() => {
                setmobileFilterSecondary(!mobileFilterSecondary)
              }}
              className="sortNowButton"
            >
              <i
                className="fa fa-sort-amount-asc"
                style={{ paddingRight: "10px", cursor: "pointer" }}
              ></i>{" "}
              Sort
            </span>
          </div>
          <br />
          <br />
        </div>
      </div>
    )
  }
  function decimalAdjust(type, value, exp) {
    if (typeof exp === "undefined" || +exp === 0) {
      return Math[type](value)
    }
    value = +value
    exp = +exp
    if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
      return NaN
    }
    value = value.toString().split("e")
    value = Math[type](+(value[0] + "e" + (value[1] ? +value[1] - exp : -exp)))
    value = value.toString().split("e")
    return +(value[0] + "e" + (value[1] ? +value[1] + exp : exp))
  }
  const ceil10 = (value, exp) => decimalAdjust("ceil", value, exp)
  function getAvgRating(feedbacks) {
    var rating = 0
    if (!feedbacks) {
      rating = 0
    } else {
      rating = 0
      var length = 0
      var feedbackvalue = Object.values(feedbacks)
      const feedbackArray = feedbackvalue
      feedbackArray.map((answer, i) => {
        rating = rating + answer.rating
        length = i + 1
      })
      rating = rating / length
      //console.log("Entered", rating, length)
      rating = ceil10(rating, -1)
    }
    return rating
  }

  function Slug(spaceDisplayName, gofloatersSpaceName, locality, city) {
    var str,
      slug = ""
    if (gofloatersSpaceName) {
      var str = gofloatersSpaceName
      str =
        str +
        "-" +
        spaceDisplayName +
        "-" +
        locality.trim().replace(".", "") +
        "-" +
        city
      str = str.trim()
      slug = str.replace(/\s+/g, "-").toLowerCase()
      slug = slug.replace("&", "and")
      slug = slug.replace("'", "")
      slug = slug.replace(",", "")
      slug = slug.replace("é", "e")
      slug = slug.replace(".", "-")
      slug = slug.replace("|", "-")
      slug = slug.replace("---", "-")
      slug = slug.replace("--", "-")
      slug = slug.replace("--", "-")
      slug = slug.replace(",", "")
    }
    if (gofloatersSpaceName === "N/A") {
      var str = spaceDisplayName.replace(/\s+/g, "-").toLowerCase()
      str = str + "-" + locality.trim().replace(".", "") + "-" + city
      str = str.trim()
      slug = str.replace(/\s+/g, "-").toLowerCase()
      slug = slug.replace("&", "and")
      slug = slug.replace("'", "")
      slug = slug.replace(",", "")
      slug = slug.replace("é", "e")
      slug = slug.replace(".", "-")
      slug = slug.replace("|", "-")
      slug = slug.replace("---", "-")
      slug = slug.replace("--", "-")
      slug = slug.replace(",", "")
    }
    return slug
  }
  function spaceTypeConverter(
    spaceType,
    monthPassAvailable,
    dayPassAvailable,
    hourPassAvailable
  ) {
    if (spaceType === "Shared Workspace" && dayPassAvailable) {
      return "office-space"
    } else if (spaceType === "Shared Workspace" && monthPassAvailable) {
      return "office-space"
    } else {
      return "meeting-space"
    }
  }
  var spaceRating
  var slug
  const cityList = cities.map(city => {
    return (
      <li>
        <a
          className={city == citySelected ? "active" : ""}
          onClick={() => {
            setcitySelected(city)
          }}
        >
          {city}
        </a>
      </li>
    )
  })
  const localityChange = e => {
    setcitySelected(e.target.value)
  }
  return (
    <div>
      <div className={"loader " + Loader}>
        <img
          src="https://assets.gofloaters.com/flyingtilo.gif"
          width="200"
          alt="Flying Tilo"
        ></img>
      </div>
      <SEOHeader title={Lists} description={searchDescription}></SEOHeader>
      <Helmet>
        <meta name="robots" content="noindex" />
        <meta name="googlebot" content="noindex"></meta>
      </Helmet>

      <div
        style={{
          position: "fixed",
          bottom: mobileFilterSecondary ? "0px" : "-100px",
          zIndex: "10",
          width: "100%",
          opacity: mobileFilterSecondary ? "1" : "0",
          background: "#fdfdfd",
          transition: "0.5s",
          overflow: "scroll",
          overflowX: "hidden",
          boxShadow: "0px -16px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div style={{ padding: "5px 15px", borderBottom: "1px solid #d6d6d6" }}>
          <p style={{ fontSize: "18px", fontWeight: "bold" }}>Sort</p>
        </div>

        <div className="col-md-12">
          <div
            className={
              filterStyle === "Popular" ? "sortingCard active" : "sortingCard"
            }
            onClick={() => {
              changeFilter("Popular")
            }}
          >
            <p>
              <b>Popular:</b> Frequently booked
            </p>
          </div>
        </div>

        <div className="col-md-12">
          <div
            className={
              filterStyle === "User Rating"
                ? "sortingCard active"
                : "sortingCard"
            }
            onClick={() => {
              changeFilter("User Rating")
            }}
          >
            <p>
              <b>User Rating:</b> Highest first
            </p>
          </div>
        </div>
        <div className="col-md-12">
          <div
            className={
              filterStyle === "PriceLow" ? "sortingCard active" : "sortingCard"
            }
            onClick={() => {
              changeFilter("PriceLow")
            }}
          >
            <p>
              <b>Price:</b> Lowest first
            </p>
          </div>
        </div>
        <div className="col-md-2">
          <div
            className={
              filterStyle === "PriceHigh" ? "sortingCard active" : "sortingCard"
            }
            onClick={() => {
              changeFilter("PriceHigh")
            }}
          >
            <p>
              <b>Price:</b> Highest first
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="listingpageTitle">{Lists}</h1>
            <p>{searchDescription}</p>
          </div>
          <div>
            <div className="col-md-12">
              <div className="officefiltercontainer">
                <ul className="OfficeFilter scrollmenunew">{cityList}</ul>
              </div>
              <br />
            </div>
          </div>
          <div className="col-md-12">
            <Sorting />
          </div>
          <div className="padding-20"></div>
          {spaceIds.length > 0
            ? spaceIds.map(List => {
                spaceRating = getAvgRating(List.feedback)
                slug = Slug(
                  List.spaceDisplayName,
                  List.gofloatersSpaceName,
                  List.address.locality,
                  List.address.city
                )
                if (citySelected == List.address.city) {
                  return (
                    <SuggestionCardVertical
                      key={List.spaceId}
                      spacetype={List.spaceType}
                      listingImg={List.photos}
                      title={List.officeSpaceType}
                      gftitle={List.gofloatersSpaceName}
                      spaceAddress={List.address.locality}
                      city={List.address.city}
                      spaceDesc={List.spaceDesc}
                      priceHr={List.priceperhr}
                      priceDay={List.priceperday}
                      priceMonth={List.pricepermonth}
                      monthPassAvailable={List.monthPassAvailable}
                      dayPassAvailable={List.dayPassAvailable}
                      hourPassAvailable={List.hourPassAvailable}
                      spaceId={List.spaceId}
                      officeSpaceType={List.officeSpaceType}
                      filter={spaceFilter}
                      spaceDisplayName={List.spaceDisplayName}
                      Distance={List.Distance}
                      Rating={List.rating}
                      Facility={List.Facility}
                      OriginalName={List.originalName}
                      Slug={"/" + spaceType + "/" + slug}
                      hasCovidSafeBadge={List.hasCovidSafeBadge}
                      online={List.online}
                      type={slug}
                      operationTiming={List.operationTiming}
                      facilitiesList={List.facilitiesList}
                    />
                  )
                }
              })
            : "No Data"}
          <div className="col-md-12">
            <div className="filterbar" style={{ marginTop: 15 }}></div>
          </div>
          <div className="col-md-12">
            <div
              className="blogContent"
              dangerouslySetInnerHTML={{ __html: searchBottomText }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

SuggestionComponent.propTypes = {
  search: PropTypes.object,
}

export default withLocation(SuggestionComponent)
