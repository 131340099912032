import React, { useState } from "react"
import "../Card/newlistingcard.scss"
import VizSensor from "react-visibility-sensor"
import { Link } from "gatsby"
import PreLoader from "./preloader.png"
import moment from "moment"
const SuggestionCardVertical = props => {
  const [imgViz, setimgViz] = useState(false)
  const [imgVizdisplay, setimgVizdisplay] = useState(false)
  const url = "https://gofloaters.com" + props.Slug
  var divStyleLoad = {
    height: "200px",
    backgroundImage: "url(" + PreLoader + ")",
    backgroundSize: "cover",
    WebkitTransition: "all", // note the capital 'W' here
    msTransition: "all", // 'ms' is the only lowercase vendor prefix
    borderRadius: "5px",
  }
  var divStyle = {
    height: "200px",
    backgroundImage: "url(" + props.listingImg[0] + ")",
    backgroundSize: "cover",
    WebkitTransition: "all", // note the capital 'W' here
    msTransition: "all", // 'ms' is the only lowercase vendor prefix
    borderRadius: "5px ",
  }

  const SubImages = image => {
    return (
      <div
        style={{
          height: "200px",
        }}
      >
        <img src={image} style={{ width: "100%" }}></img>
      </div>
    )
  }
  var str = ""
  const HourPrice = () => {
    return (
      <b>
        ₹ {props.priceHr}/hour
        <br />
      </b>
    )
  }

  const MonthlyPrice = () => {
    return (
      <b>
        ₹ {props.priceMonth}/month
        <br />
      </b>
    )
  }
  const DailyPrice = () => {
    return (
      <b>
        ₹ {props.priceDay}/day
        <br />
      </b>
    )
  }
  const DailyFree = () => {
    return <span className="priceTag">Daily: NA</span>
  }
  function decimalAdjust(type, value, exp) {
    // If the exp is undefined or zero...
    if (typeof exp === "undefined" || +exp === 0) {
      return Math[type](value)
    }
    value = +value
    exp = +exp
    // If the value is not a number or the exp is not an integer...
    if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
      return NaN
    }
    // Shift
    value = value.toString().split("e")
    value = Math[type](+(value[0] + "e" + (value[1] ? +value[1] - exp : -exp)))
    // Shift back
    value = value.toString().split("e")
    return +(value[0] + "e" + (value[1] ? +value[1] + exp : exp))
  }
  const ceil10 = (value, exp) => decimalAdjust("ceil", value, exp)

  function tConv24(time24) {
    var ts = time24
    var H = +ts.substr(0, 2)
    var h = H % 12 || 12
    h = h < 10 ? "0" + h : h // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM"
    ts = h + ts.substr(2, 3) + ampm
    return ts
  }
  var today = new Date()
  var day = today.getDay()

  var daylist = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ]
  var todayDay = daylist[day]
  function timeCal(todayDay) {
    // console.log("dataTime", props.operationTiming.days)
    switch (todayDay) {
      case "Monday":
        if (props.operationTiming.days.monday?.holiday) return "Holiday"
        else {
          return (
            tConv24(props.operationTiming.days.monday.from) +
            " - " +
            tConv24(props.operationTiming.days.monday.to)
          )
        }
      case "Tuesday":
        if (props.operationTiming.daystuesday?.holiday) return "Holiday"
        else {
          return (
            tConv24(props.operationTiming.days.tuesday.from) +
            " - " +
            tConv24(props.operationTiming.days.tuesday.to)
          )
        }
      case "Wednesday":
        if (props.operationTiming.days.wednesday?.holiday) return "Holiday"
        else {
          return (
            tConv24(props.operationTiming.days.wednesday.from) +
            " - " +
            tConv24(props.operationTiming.days.wednesday.to)
          )
        }
      case "Thursday":
        if (props.operationTiming.days.thursday?.holiday) return "Holiday"
        else {
          return (
            tConv24(props.operationTiming.days.thursday.from) +
            " - " +
            tConv24(props.operationTiming.days.thursday.to)
          )
        }
      case "Friday":
        if (props.operationTiming.days.friday?.holiday) return "Holiday"
        else {
          return (
            tConv24(props.operationTiming.days.friday.from) +
            " - " +
            tConv24(props.operationTiming.days.friday.to)
          )
        }
      case "Saturday":
        if (props.operationTiming.days.saturday?.holiday) return "Holiday"
        else {
          return (
            tConv24(props.operationTiming.days.saturday.from) +
            " - " +
            tConv24(props.operationTiming.days.saturday.to)
          )
        }
      case "Sunday":
        if (props.operationTiming.days.sunday?.holiday) return "Holiday"
        else {
          return (
            tConv24(props.operationTiming.days.sunday.from) +
            " - " +
            tConv24(props.operationTiming.days.sunday.to)
          )
        }
      default:
        return ""
    }
  }
  function facilitiesIcons(facility) {
    switch (facility) {
      case "Hi Speed WiFi":
        return (
          <img
            src="https://assets.gofloaters.com/icons/svg/hi_speed_wifi.svg"
            alt={facility}
            title={facility}
            style={{
              width: "45px",
              padding: "10px 7px",
            }}
          ></img>
        )
      case "Power Backup":
        return (
          <img
            src="https://assets.gofloaters.com/icons/svg/power_backup.svg"
            alt={facility}
            title={facility}
            style={{
              width: "45px",
              padding: "10px 7px",
            }}
          ></img>
        )
      case "AC":
        return (
          <img
            src="https://assets.gofloaters.com/icons/svg/ac.svg"
            alt={facility}
            title={facility}
            style={{
              width: "45px",
              padding: "10px 7px",
            }}
          ></img>
        )
      case "Metro Nearby":
        return (
          <img
            src="https://assets.gofloaters.com/icons/svg/metro_nearby.svg"
            alt={facility}
            title={facility}
            style={{
              width: "45px",
              padding: "10px 7px",
            }}
          ></img>
        )
      case "Free Parking":
        return (
          <img
            src="https://assets.gofloaters.com/icons/svg/free_parking.svg"
            alt={facility}
            title={facility}
            style={{
              width: "45px",
              padding: "10px 7px",
            }}
          ></img>
        )
      case "Rest Rooms":
        return (
          <img
            src="https://assets.gofloaters.com/icons/svg/rest_rooms.svg"
            alt={facility}
            title={facility}
            style={{
              width: "45px",
              padding: "10px 7px",
            }}
          ></img>
        )
      case "Coffee/Tea":
        return (
          <img
            src="https://assets.gofloaters.com/icons/svg/coffee_tea.svg"
            alt={facility}
            title={facility}
            style={{
              width: "45px",
              padding: "10px 7px",
            }}
          ></img>
        )
      case "Pantry":
        return (
          <img
            src="https://assets.gofloaters.com/icons/svg/pantry.svg"
            alt={facility}
            title={facility}
            style={{
              width: "45px",
              padding: "10px 7px",
            }}
          ></img>
        )
      case "Meeting Rooms":
        return (
          <img
            src="https://assets.gofloaters.com/icons/svg/meeting_rooms.svg"
            alt={facility}
            title={facility}
            style={{
              width: "45px",
              padding: "10px 7px",
            }}
          ></img>
        )
      case "Outside Food Allowed":
        return (
          <img
            src="https://assets.gofloaters.com/icons/svg/outside_food_allowed.svg"
            alt={facility}
            title={facility}
            style={{
              width: "45px",
              padding: "10px 7px",
            }}
          ></img>
        )

      default:
        return ""
    }
  }
  const ratingText = rating => {
    if (rating > 4.5) {
      return "Very Good"
    } else if (rating >= 3.5 && rating <= 4.5) {
      return "Good"
    } else if (rating >= 2.5 && rating <= 3.5) {
      return "Average"
    } else if (rating < 2.5) {
      return "Below Average"
    }
  }

  const Images = prop => {
    return (
      <div className="col-md-4 col-4" style={{ padding: "5px" }}>
        <div className="">
          <img
            src={prop.image}
            style={{ width: "100%", borderRadius: "5px" }}
          />
        </div>
      </div>
    )
  }

  const ImagesLoop = prop => {
    const rows = []
    for (let i = 1; i < 4; i++) {
      rows.push(<Images image={props.listingImg[i]} />)
    }
    return rows
  }

  // ;<div className="col-md-4">
  //   <div style={{ paddingTop: "5px" }}>
  //     <img
  //       src={props.listingImg[i]}
  //       style={{ width: "100%", borderRadius: "5px" }}
  //     />
  //   </div>
  // </div>
  return (
    <div className={"col-md-12  listingCardPaddingNew" + str}>
      <a href={url} target="_blank">
        <div
          className="listingCardNew"
          title={props.gftitle + " - " + props.OriginalName}
          style={{ padding: "15px 10px" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="listingimagesnew">
                  <VizSensor
                    partialVisibility
                    onChange={isVisible => {
                      setimgViz(isVisible)
                      if (!imgVizdisplay) {
                        setimgVizdisplay(isVisible)
                      }
                    }}
                  >
                    <div className="row">
                      <div className="col-md-12" style={{ padding: "5px" }}>
                        <div
                          style={imgVizdisplay ? divStyle : divStyleLoad}
                          className={props.online ? "" : "inactiveImg"}
                        ></div>
                      </div>
                      <ImagesLoop />
                      {/* <div className="col-md-12">
                        <div className="row">
                          {props.listingImg.map((item, index) => (
                            <Images image={item} key={index} />
                          ))}
                        </div>
                      </div> */}
                    </div>
                  </VizSensor>
                </div>
              </div>
              <div className="col-md-6 rightBorder">
                {props.spacetype != "Cafe" ? (
                  <h2
                    className="mobilePadder"
                    style={{
                      fontWeight: "bold",
                      fontSize: "22px",
                      paddingTop: "0px",
                    }}
                    title={props.gftitle + " - " + props.spaceDisplayName}
                  >
                    {props.gftitle} - {props.spaceDisplayName}
                    {/* {" in "}
                    {props.spaceAddress}, {props.city} */}
                  </h2>
                ) : (
                  ""
                )}
                <p style={{ fontSize: "20px", color: "#878787" }}>
                  {props.OriginalName}
                </p>
                <p style={{ display: "flex" }}>
                  <span
                    className="fa fa-map-marker"
                    style={{
                      fontSize: "24px",
                      width: "24px",
                      textAlign: "center",
                      color: "#464646",
                      marginRight: "5px",
                    }}
                  ></span>{" "}
                  {props.spaceAddress}, {props.city}
                </p>
                <p style={{ display: "flex" }}>
                  <span
                    className="fa fa-clock-o"
                    style={{
                      fontSize: "24px",
                      width: "24px",
                      textAlign: "center",
                      color: "#464646",
                      marginRight: "5px",
                    }}
                  ></span>
                  {" Today " + timeCal(todayDay)}
                </p>
                <div>
                  {props.facilitiesList.map(facilities => {
                    return facilitiesIcons(facilities)
                  })}
                </div>
                <br />

                {/* {props.facilitiesList.("AC") > 1 ? (
                  <img
                    src="https://assets.gofloaters.com/icons/svg/ac.svg"
                    alt="AC"
                    style={{
                      width: "45px",
                      border: "1px solid #2193B0",
                      padding: "10px 7px",
                      borderRadius: "5px",
                    }}
                  />
                ) : (
                  ""
                )} */}
              </div>
              <div className="col-md-2 priceAligner">
                {" "}
                <div style={{ textAlign: "right" }}>
                  {props.Rating != 0 ? (
                    <div>
                      <p style={{ fontWeight: "bold", fontSize: "18px" }}>
                        {ratingText(props.Rating)}
                      </p>
                      <p>
                        <span className="fa fa-star"></span>{" "}
                        {" " + props.Rating}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <p className="priceTagVertical">
                  {props.type !== "office-space" &&
                  props.hourPassAvailable &&
                  props.priceHr !== " " ? (
                    <HourPrice />
                  ) : (
                    ""
                  )}

                  {props.type !== "office-space" &&
                  props.dayPassAvailable &&
                  props.priceDay !== " " ? (
                    <DailyPrice />
                  ) : (
                    ""
                  )}

                  {props.type !== "coworking-space" &&
                  props.monthPassAvailable &&
                  props.priceMonth !== " " ? (
                    <MonthlyPrice />
                  ) : (
                    ""
                  )}
                </p>
                <div className="text-center cardButtonVertical">
                  {props.online ? (
                    <a href={url} target="_blank">
                      Book Now
                    </a>
                  ) : (
                    <a href={url} target="_blank">
                      Currently unavailable
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  )
}
export default SuggestionCardVertical
